import { useSplitTreatments as useTreatments } from '@splitsoftware/splitio-react';
import { LocalStorage } from '@mentimeter/storage';
import { getBrowserLanguage } from '../utils/getBrowserLanguage';

export const useSplitTreatments = (splitNames: string[]) => {
  const { treatments } = useTreatments({
    names: splitNames,
    attributes: { browserLanguage: getBrowserLanguage() },
  });

  return applyTreatmentOverrides(treatments);
};

/**
 * To use the split override feature, you need to set the menti_split_overrides in the browser.
 * E.g. by localStorage.setItem('menti_split_overrides', JSON.stringify({ "Live_Sticky_Navigation": "off" }))
 */
function applyTreatmentOverrides(baseTreatments: SplitIO.TreatmentsWithConfig) {
  const splitOverrides = LocalStorage.getJSONItem('menti_split_overrides') as
    | SplitIO.Treatments
    | undefined;
  if (splitOverrides) {
    return Object.keys(baseTreatments).reduce((prev, curr) => {
      if (!splitOverrides[curr]) return prev;
      return {
        ...prev,
        [curr]: { treatment: splitOverrides[curr], config: null },
      } as SplitIO.TreatmentsWithConfig;
    }, baseTreatments);
  }

  return baseTreatments;
}
